import { render, staticRenderFns } from "./aftercall.vue?vue&type=template&id=93b42abe&scoped=true"
import script from "./aftercall.vue?vue&type=script&lang=js"
export * from "./aftercall.vue?vue&type=script&lang=js"
import style0 from "./aftercall.vue?vue&type=style&index=0&id=93b42abe&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93b42abe",
  null
  
)

export default component.exports