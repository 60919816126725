<template>
  <FullScreenDialog
    :show-icon-loader="loading.appointment"
    :title="title"
    :canBeClosed="false"
    :backgroundStyle="'gradient-alt'"
  >
    <template v-slot:body>
      <template v-if="!loading.appointment && specialist">
        <PersonCard
          style="margin-top: -16px"
          :title="specialist.name"
          :image="specialist.picture"
          :middle-line="specialist.type"
        />

        <Button
          class="margin-top-lg"
          :text="$translations.aftercall.buttons.continue"
          @clicked="handleKeepSpecialist"
          :disable="loading.button"
        />

        <Button
          class="margin-top"
          type="outline"
          color="gray"
          :text="$translations.aftercall.buttons['change-specialist']"
          @clicked="handleGoSurvey"
          :disable="loading.button"
        />
      </template>
    </template>
  </FullScreenDialog>
</template>

<script>
import {
  FullScreenDialog,
  Button,
  PersonCard,
  AfterCallWarranty
} from '@seliaco/red-panda'
import { WarrantyEvent } from '@/types/events'

export default {
  name: 'Aftercall',
  components: {
    Button,
    FullScreenDialog,
    PersonCard
  },
  props: {
    appointmentId: String
  },
  data () {
    return {
      appointment: null,
      specialist: null,
      loading: {
        appointment: true,
        button: false
      }
    }
  },
  beforeMount () {
    this.getAppointment()
  },
  methods: {
    async getAppointment () {
      const specialistFirstSession =
        await AfterCallWarranty.specialistFirstSession(this.appointmentId)

      if (
        !specialistFirstSession.isFirstSession ||
        specialistFirstSession?.specialist?.code === 'COUNSELING'
      ) {
        return this.$router.push({
          name: 'SessionCompleted',
          params: { id: this.appointmentId }
        })
      }

      WarrantyEvent.Warranty_Call_Specialist_Continue_Viewed()

      this.specialist = specialistFirstSession.specialist
      this.appointment = {
        id: this.appointmentId
      }
      this.loading.appointment = false
    },
    handleGoSurvey () {
      this.loading.button = true
      WarrantyEvent.Warranty_Call_Specialist_Continue_Clicked(
        'No, quiero cambiar de especialista'
      )
      this.$globalEvent.$emit('modal/aftercall-survey', {
        showDialog: {
          aftercallSurvey: { open: true, close: false }
        },
        appointment: this.appointment
      })
    },
    handleKeepSpecialist () {
      this.loading.button = true
      WarrantyEvent.Warranty_Call_Specialist_Continue_Clicked(
        'Si, quiero continuar'
      )
      this.$router
        .push({
          name: 'SessionCompleted',
          params: { id: this.appointmentId }
        })
        .then(async () => {
          await AfterCallWarranty.confirmSpecialistPsychology()
        })
    }
  },
  computed: {
    title () {
      if (this.loading.appointment) {
        return null
      }

      return this.$translations.aftercall.title
    }
  }
}
</script>

<style lang="sass" scoped>
::v-deep .modal-fullscreen-content.card
  border: none
  max-width: 480px
::v-deep .heading
  padding: 6px
</style>
